const removePasteTag = () => {
  setTimeout(() => {
    const imageOrVideo = document.querySelectorAll(".ql-editor img, .ql-editor video") as NodeListOf<HTMLImageElement | HTMLVideoElement>;
    imageOrVideo.forEach(item => {
      let src = null;

      switch (item.nodeName) {
        case "VIDEO":
          src = item.getAttribute("poster");
          break;
        case "IMG":
          src = item.getAttribute("src");
          break;
      }

      if (!src) {
        item.remove();
      }
    });
    removeTagAndStyle();
  }, 100);
};

const removeTagAndStyle = () => {
  const notRemoveTag = ".ql-editor *:not(p):not(img):not(video):not(source)";

  (document.querySelectorAll(notRemoveTag) as NodeListOf<HTMLElement>).forEach(item => {
    if (item.nodeName === "A") {
      const regexLink = /(https?:\/\/)[^\s]+[.][^\s]+|(www.)[^\s]+[.][^\s]+/g;
      const match = item.innerText.match(regexLink);
      if (match && (match[match.length - 1].indexOf("www.") === 0 || match[match.length - 1].indexOf("http") === 0)) {
        return;
      }
    }

    item.outerHTML = item.innerText;
  });

  const styleRemoveTag = ".ql-editor p .ql-editor img, .ql-editor video, .ql-editor a";

  document.querySelectorAll(styleRemoveTag).forEach(item => {
    item.removeAttribute("style");
    item.removeAttribute("class");
  });
};

export default removePasteTag;
