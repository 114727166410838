import React, { useRef, useState } from "react";
import { Link } from "react-router-dom";
import styled from "styles/theme";
import { useTranslation } from "react-i18next";
import queryString from "query-string";
import Photo from "components/User/Photo";
import Author from "./Author";
import Comment from "./Comment";
import { common, dateDisplay, regex } from "utils";
import { IconArrow02, IconClip, IconComment, IconLike, IconLock, IconPick, IconScrape, IconThum, IconVideo } from "assets/icon/svg";
import thumLoad from "assets/icon/thum_load.png";
import { useMutation } from "@apollo/react-hooks";
import { GET_POST, TOGGLE_LIKE_POST, TOGGLE_SCRAPE_POST, VIEW_POST } from "api/Board";
import { Toast } from "styles";
import { getCookieState } from "apollo/LocalState";
import { replaceTag } from "./Tag";
import Ellipsis from "./EllipsisComponent";
import { ReportTypography } from "../../Common/ReportTypography";
import { isGif } from "../../../utils/string";

const ItemComponent: React.FC<IPost> = ({
  categoryActive,
  id: postId,
  category,
  author,
  title,
  content,
  files,
  createdAt,
  picked,
  hitCount,
  likeCount,
  scrapeCount,
  restricted,
  comments,
  me,
  secreted,
  sortBy,
  feed,
  setWrite,
  hightlight,
  isProfile,
  handleMoveToProfile,
  blockedBy
}) => {
  const { t } = useTranslation("Board");
  const { cpId } = getCookieState;
  const { search } = window.location;
  const queryPath = queryString.parse(search);
  const titleRef = useRef<HTMLHeadingElement>(null);
  const contentRef = useRef<HTMLDivElement>(null);

  const [toast, setToast] = useState<IToast>();

  let thumShow = false;
  let fileShow = false;
  const thumFile: IFile[] = [];

  if (files.length > 0) {
    files.forEach(item => {
      const type = `${item.type}`;

      switch (type.split("/")[0]) {
        case "video":
        case "image":
          thumShow = true;
          break;
        default:
          fileShow = true;
          break;
      }

      if (type.indexOf("image") > -1 || type.indexOf("video") > -1) {
        thumFile.push({ ...item, type: type.split("/")[0] });
      }
    });
  }

  const [toggleLikePostMutation] = useMutation(TOGGLE_LIKE_POST);
  const [toggleScrapePostMutation] = useMutation(TOGGLE_SCRAPE_POST);

  const [viewPostMutation] = useMutation(VIEW_POST, {
    update: (store, { data }) => {
      try {
        const storeGetPost: any = store.readQuery({
          query: GET_POST,
          variables: { id: postId }
        });

        if (storeGetPost) {
          store.writeQuery({
            query: GET_POST,
            variables: { id: postId },
            data: {
              getPost: {
                ...storeGetPost.getPost,
                hitCount: data.viewPost.hitCount
              }
            }
          });
        }
      } catch (err) {
        // console.log(err, "err");
      }
    }
  });

  const handleViewCount = () => {
    if (!me.posted) {
      viewPostMutation({ variables: { id: postId } });
    }
  };

  const handleScrape = async () => {
    try {
      const { data } = await toggleScrapePostMutation({ variables: { id: postId } });
      const message = data.toggleScrapePost.me.scraped ? t("scrape") : t("scrapeOff");
      setToast({ id: Math.random(), message });
    } catch (err) {
      // console.log(err)
    }
  };

  if (content && (content.indexOf("<video") > -1 || content.indexOf("<img") > -1)) {
    content = content.replace(regex.video, "").replace(regex.image, "");
  }

  const keyword = queryPath.keyword && (queryPath.keyword as string).replace(regex.keywordCheck, "");
  const tag = replaceTag(queryPath.type, keyword, hightlight);

  const lock = !me.posted && (secreted || (restricted && !blockedBy));
  const eventDisable = (me.reported ?? false) || !!blockedBy;
  const imageUrl = (url?: string) => {
    if (isGif(url)) return url;
    return `${url}?size=feed`;
  };

  return (
    <Item id={`d${postId}`} className={sortBy === "POPULARITY" ? "popular" : feed ? "feed" : ""}>
      {(picked || !categoryActive) && sortBy !== "POPULARITY" && (
        <PickArea>
          {picked && <IconPick />}
          {!categoryActive && category.id && (
            <Link to={`/${cpId ? `${cpId}/` : ""}board/${category.id}${sortBy ? `?sortBy=${sortBy}` : ""}`} onClick={() => common.scrollRemove("board", category.id)}>
              {category.title} <IconArrow02 direction="right" />
            </Link>
          )}
        </PickArea>
      )}
      {lock ? (
        <SecretWrap className={feed ? "feed" : ""}>
          <IconLock lock /> {t("privatePost")}
        </SecretWrap>
      ) : (
        <>
          {sortBy !== "POPULARITY" && (
            <Author
              moveLink={`/${cpId ? `${cpId}/` : ""}board${categoryActive ? `/${category?.id}` : ""}/view/${postId}${sortBy ? `?sortBy=${sortBy}` : ""}`}
              hitCallback={handleViewCount}
              id={postId}
              categoryId={category ? category.id : undefined}
              secreted={secreted}
              feed={feed}
              author={author}
              createdAt={createdAt}
              hitCount={hitCount}
              me={me}
              setWrite={setWrite}
              isProfile={isProfile}
              handleMoveToProfile={handleMoveToProfile}
              reported={me.reported}
              blockedBy={blockedBy}
            />
          )}
          {!me.reported && !blockedBy ? (
            <PostWrap className={`${sortBy === "POPULARITY" ? "popular" : ""} ${feed ? "feed" : thumShow && !isProfile ? "thum" : ""}`}>
              {sortBy === "POPULARITY" && <Photo size={"sm"} src={author.picture} className={"photo"} />}

              <Title ref={titleRef}>
                <Ellipsis text={tag.getTitle(title)} maxLine={2} />
                {fileShow && <IconClip />}
              </Title>

              {sortBy === "POPULARITY" && <CreatedAt>{dateDisplay(createdAt)}</CreatedAt>}
              {feed && content && (
                <Content ref={contentRef}>
                  <Ellipsis text={tag.getContent(content)} maxLine={5} moreText={`${t("seeMore")}`} />
                </Content>
              )}
              {!isProfile && thumShow && (
                <ThumWrap className={feed ? "feed-thum" : "list-thum"}>
                  {feed &&
                    thumFile.slice(0, 2).map((item, index) => (
                      <Thumnail
                        key={index}
                        style={{ backgroundImage: `url('${imageUrl(item.url)}'), url(${thumLoad})`, backgroundColor: item.type === "video" ? "#000" : "transparent" }}
                        className={thumFile.length > 1 ? "half" : ""}
                      >
                        {item.type === "image" && <img src={imageUrl(item.url)} alt={item.name} />}
                        {item.type === "video" && (
                          <>
                            <img src={`${item.url}?size=feed`} className={"video"} alt={item.name} />
                            <PlayView>
                              <IconVideo />
                            </PlayView>
                          </>
                        )}
                      </Thumnail>
                    ))}
                  {!feed && thumFile.length > 0 && (
                    <>
                      {thumFile[0].type === "image" && (
                        <>
                          <img src={`${thumFile[0].url}?size=thumbnail`} alt={thumFile[0].name} />
                        </>
                      )}
                      {thumFile[0].type === "video" && (
                        <>
                          <img src={`${thumFile[0].url}?size=thumbnail`} alt={thumFile[0].name} />
                          <span className={"cover"} />
                          {thumFile.length < 2 && <IconThum video={true} />}
                        </>
                      )}
                    </>
                  )}
                  {thumFile.length > (feed ? 2 : 1) && <ThumCount className={"thum-count"}>+{thumFile.length - (feed ? 2 : 1)}</ThumCount>}
                </ThumWrap>
              )}
              <MoveView
                to={`/${cpId ? `${cpId}/` : ""}board${categoryActive ? `/${category?.id}` : ""}/view/${postId}${sortBy ? `?sortBy=${sortBy}` : ""}`}
                onClick={handleViewCount}
              />
            </PostWrap>
          ) : (
            <ReportTypography blockedBy={blockedBy} reported={me.reported} isComment={false} />
          )}

          {sortBy !== "POPULARITY" && (
            <PostInfo className={feed ? "feed" : thumShow && !isProfile ? "thum" : ""}>
              <ElNum
                disable={eventDisable}
                onClick={() => {
                  !eventDisable && toggleLikePostMutation({ variables: { id: postId } });
                }}
              >
                <IconLike active={me.liked} />
                {likeCount}
              </ElNum>
              {
                <ElNum
                  disable={eventDisable}
                  onClick={() => {
                    !eventDisable && handleScrape();
                  }}
                >
                  <IconScrape active={me.scraped} />
                  {scrapeCount}
                </ElNum>
              }
              {category.allowComments !== "DISABLED" && (
                <ElNum className="comment" disable={!!blockedBy}>
                  <Link
                    style={{ pointerEvents: blockedBy ? "none" : "auto" }}
                    to={`/${cpId ? `${cpId}/` : ""}board${categoryActive ? `/${category?.id}` : ""}/view/${postId}${sortBy ? `?sortBy=${sortBy}` : ""}#comment`}
                  >
                    <IconComment />
                    {comments?.totalCount}
                  </Link>
                </ElNum>
              )}
            </PostInfo>
          )}
          {feed && category.allowComments !== "DISABLED" && comments && comments.items.length > 0 && !me.reported && (
            <CommentWrap>
              {comments.items.slice(0, 2).map((item: IComment, index: number) => (
                <Comment
                  {...item}
                  key={index}
                  moveLink={`/${cpId ? `${cpId}/` : ""}board${categoryActive ? `/${category?.id}` : ""}/view/${postId}${sortBy ? `?sortBy=${sortBy}` : ""}#comment`}
                />
              ))}
            </CommentWrap>
          )}
        </>
      )}
      {toast && <Toast toast={toast} setToast={setToast} />}
    </Item>
  );
};

ItemComponent.defaultProps = {};

export default ItemComponent;

const Item = styled.div`
  position: relative;
  padding: 20px 0;
  background: ${p => p.theme.COLORS.BG_BODY};

  &.feed {
    padding: 20px 0 0;
  }

  & + div {
    border-top: 4px solid ${p => p.theme.COLORS.BORDER_REGULAR};
  }

  &.popular {
    & + div {
      border-top: 1px solid ${p => p.theme.COLORS.BORDER_REGULAR};
    }
  }
`;

const PickArea = styled.div`
  display: flex;
  align-items: center;
  padding: 0 20px;

  + div {
    margin-top: 10px;
  }

  & svg {
    &:first-of-type {
      margin-right: 10px;
    }

    &:last-of-type:first-of-type {
      margin-left: 2px;
    }
  }

  a {
    ${p => p.theme.TEXTS.BOARD_LINK_TITLE};
    display: flex;
    align-items: center;
  }
`;

const SecretWrap = styled.div`
  ${p => p.theme.TEXTS.LIST_TITLE};
  display: flex;
  align-items: center;
  padding: 0 20px;

  &.feed {
    padding-bottom: 20px;
  }

  & svg {
    margin-right: 5px;
  }
`;

const PostWrap = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  margin-top: 10px;
  padding: 0 20px;

  &.popular {
    margin-top: 0;
    padding: 0 20px 0 70px;

    & .photo {
      position: absolute;
      top: 0;
      left: 20px;
      z-index: 0;
    }
  }

  &.thum {
    padding-right: 105px;
    min-height: 68px;

    & .list-thum {
      display: flex;
      align-items: center;
      justify-content: center;
      position: absolute;
      top: 0;
      right: 20px;
      width: 70px;
      height: 65px;
      border-radius: 6px;
      overflow: hidden;

      & img,
      & video {
        width: auto;
        height: 130px;
        background: url(${thumLoad}) center center no-repeat;
        background-size: 100%;

        &.hide {
          opacity: 0;

          & + .cover {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: #000;
            opacity: 0.4;
            z-index: 0;
          }
        }
      }

      & svg {
        position: absolute;
        bottom: 5px;
        right: 5px;
      }
    }

    & .post-wrap {
      margin-top: auto;
    }
  }

  &.feed {
    padding-bottom: 20px;
    border-bottom: 1px solid ${p => p.theme.COLORS.BORDER_REGULAR};

    & h2 {
      font-size: ${p => p.theme.SIZE.n18};
    }

    & .feed-thum {
      margin-top: 10px;

      & > div {
        width: calc(100vw - 40px);
        height: calc(56vw - 34px);
        text-align: center;
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        overflow: hidden;

        @media screen and (min-width: 650px) {
          width: 100%;
          height: 304px;
        }

        & img,
        & video {
          width: calc(100vw - 40px);
          height: calc(56vw - 34px);
          object-fit: cover;
          vertical-align: top;

          @media screen and (min-width: 650px) {
            width: 100%;
            height: 304px;
          }
        }

        & img {
          opacity: 0;
        }
      }

      .thum-count {
        bottom: 25px;
        right: 25px;
      }
    }
  }
`;

const Title = styled.h2`
  ${p => p.theme.TEXTS.LIST_TITLE};
  order: 0;

  & svg {
    vertical-align: -2px;
  }

  & span {
    em {
      font-weight: 500;
      background: ${p => p.theme.COLORS.EL_IMG};
    }
  }
`;

const CreatedAt = styled.div`
  ${p => p.theme.TEXTS.LIST_PROFILE_TIME}
  margin-top: 10px;
`;

const Content = styled.div`
  ${p => p.theme.TEXTS.BOARD_CONTENT};
  margin-top: 12px;
  word-break: break-all;

  & em {
    font-weight: 500;
    background: ${p => p.theme.COLORS.EL_IMG};
  }
`;

const ThumWrap = styled.div`
  order: 1;
  display: flex;
`;

const Thumnail = styled.div`
  position: relative;

  &.half {
    flex-basis: 50%;
    width: 50% !important;
    overflow: hidden;

    video,
    img {
      width: 100% !important;
      object-fit: cover;
    }

    & + .half {
      margin-left: auto;
      flex-basis: calc(50% - 1px);
      width: calc(50% - 1px) !important;
    }
  }
`;

const ThumCount = styled.span`
  ${p => p.theme.TEXTS.INFO_TXT_ALL_NUM};
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: 5px;
  right: 5px;
  padding: 0 6px;
  height: 18px;
  color: ${p => p.theme.COLORS.WHITE};
  border-radius: 4px;
  background: rgba(0, 0, 0, 0.6);
`;

const PlayView = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;

  /* background-color: rgba(0, 0, 0, 0.4); */
`;

const PostInfo = styled.div`
  ${p => p.theme.TEXTS.LIST_EL_NUM};
  order: 2;
  display: flex;
  position: relative;
  margin-top: 6px;
  padding: 0 20px;
  z-index: 1;

  &.thum {
    position: absolute;
    bottom: 23px;
    width: calc(100% - 88px);
  }

  &.feed {
    align-items: center;
    margin-top: 0;
    width: 100%;
    height: 54px;
  }
`;

interface ElNumProps {
  disable: boolean;
}

const ElNum = styled.span<ElNumProps>`
  ${p => p.theme.TEXTS.LIST_EL_NUM};
  display: flex;
  align-items: center;
  cursor: ${p => (p.disable ? "auto" : "pointer")};
  opacity: ${p => (p.disable ? 0.4 : 1)};

  & svg {
    margin-right: 2px;
  }

  & + span {
    margin-left: 15px;
  }

  &.comment {
    margin-left: auto;

    & svg {
      margin-right: 4px;
      vertical-align: middle;
    }

    & a {
      ${p => p.theme.TEXTS.LIST_COMMENT_NUM};
    }
  }
`;

const MoveView = styled(Link)`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
`;

const CommentWrap = styled.div`
  padding-bottom: 15px;
`;
