import React, { useState, useEffect, ChangeEvent } from "react";
import { withRouter } from "react-router-dom";
import styled, { theme as themeCur } from "styles/theme";
import { useTranslation } from "react-i18next";
import cookie from "js-cookie";
import Quill from "quill";
// import "quill-mention";
import { autoLinkTag, removePasteTag, tooltipUitl } from "utils/quill";
import { CustomImageEmbed, CustomVideoEmbed } from "utils/quill/blots/block/embed";

import "styles/Quill/light.css";
import queryString from "query-string";

import { IconBack, IconLock, IconArrow01, IconEmptyCheck, IconEmptyComment, IconEmptyCancel, IconEmptyTrash, IconEmptyAlert } from "assets/icon/svg";

import { Modal, Toast } from "styles";
import { Toolbar, File } from "components/Board";
import Category from "components/Common/Category";

import { common, appNative } from "utils";

import { useQuery, useMutation } from "@apollo/react-hooks";
import { GET_CATEGORY_POST, GET_CATEGORY, CREATE_POST, UPDATE_POST, GET_MODIFY_WRITE, GET_USER_WRITTEN, SEARCH_TAGS } from "api/Board";

import { getCookieState } from "apollo/LocalState";

let openStatus = true;
let loadPostCall = true;
let quill: Quill | null | false;
// let quillFocus: any;
// let getTags: ITag[] | null[] = [];
// let globalMentionFunc: Function | null;
// let enterMention = false;
// let arrowMention = false;

const WriteComponent: React.FC<IWriteComponent> = ({ postId, userId, categoryData, detailMode, setWrite, history, ...props }) => {
  const { t } = useTranslation("Write");
  const registering = postId ? false : true;
  const { cpId } = getCookieState;

  const { userAgent } = window.navigator;

  const { search } = props.location;

  const queryPath = queryString.parse(search);

  const sortBy = queryPath.sortBy as SortType;

  const [categoryValue, setCategoryValue] = useState<IWriteCtg | null>();

  const categoryId = categoryValue?.id ?? props.categoryId ?? props.match.params?.categoryId;

  const [subjectValue, setSubjectValue] = useState<string>("");
  const [sublenModal, setSublenModal] = useState<boolean>(false);
  const [allowSecrets, setAllowSecrets] = useState<allowSecretType>("ALWAYS");
  const [lockState, setLockState] = useState<boolean>(false);
  const [fileList, setFileList] = useState<IFile[]>([]);
  const [fileUrlList, setFileUrlList] = useState<string[]>([]);
  const [confirmValue, setConfirmValue] = useState<boolean>(postId ? true : false);
  const [completeValue, setCompleteValue] = useState<boolean>(false);
  const [movePageId, setmovePageId] = useState<string>(postId ? postId : "");

  const [ctgModal, setCtgModal] = useState<boolean>(false);
  const [toast, setToast] = useState<IToast>();
  const [cancelModal, setCancelModal] = useState<boolean>(false);
  const [changeTopicModal, setChangeTopicModal] = useState<string | undefined>(undefined);
  const [isContentsEmpty, setIsContentsEmpty] = useState<boolean>(true);
  // const [isMention, setIsMention] = useState<boolean>(false);

  const { data: writeData } = useQuery(GET_MODIFY_WRITE, {
    fetchPolicy: "cache-and-network",
    variables: { id: postId },
    skip: !postId,
    onCompleted: data => {
      if (data.getPost) {
        setCategoryValue({ ...data.getPost.category, id: data.getPost.category.id, title: data.getPost.category.title });
        setAllowSecrets(data.getPost.category.allowSecrets);
        setSubjectValue(data.getPost.title);
        setFileList(data.getPost.files);

        const urlList = data.getPost.files.reduce((acc: string[], cur: IFile) => {
          if (cur.url) {
            acc.push(cur.url);
          }
          return acc;
        }, []);

        setFileUrlList(urlList);
        setLockState(data.getPost.secreted);
      }
    }
  });

  const { data: _ } = useQuery(GET_CATEGORY, {
    fetchPolicy: "cache-and-network",
    variables: { id: categoryId },
    skip: !categoryId,
    onCompleted: data => {
      if (data.getCategory) {
        if (categoryValue && getSecreted(categoryValue?.allowSecrets, lockState) !== getSecreted(data.getCategory.allowSecrets, lockState)) {
          const message = data.getCategory.allowSecrets === "ALWAYS" ? t("changePrivatePost") : t("changePublicPost");
          setToast({ id: Math.random(), message });
        }

        setCategoryValue(data.getCategory);
        setAllowSecrets(data.getCategory.allowSecrets);

        setLockState(getSecreted(data.getCategory.allowSecrets, lockState) ?? false);
      }
    }
  });

  // const [searchTags] = useLazyQuery(SEARCH_TAGS, {
  //   fetchPolicy: "network-only",
  //   onCompleted: data => {
  //     if (data) {
  //       getTags = data.searchTags.reduce((acc: ITag[], cur: IReciveTag) => {
  //         const result = acc;
  //         result.push({
  //           id: cur.count,
  //           value: cur.text
  //         });
  //         return result;
  //       }, []);

  //       globalMentionFunc && globalMentionFunc(getTags);

  //       const mentionEl = document.querySelector(".ql-mention-list");
  //       const seletedEl = mentionEl?.querySelector(".selected");
  //       seletedEl?.classList.remove("selected");
  //     }
  //   }
  // });

  const limit = 10;

  const [WritePostMutation] = useMutation(postId ? UPDATE_POST : CREATE_POST, {
    refetchQueries: [
      { query: GET_CATEGORY_POST, variables: { id: categoryId, limit, nextToken: null, options: { sortBy } } },
      { query: GET_USER_WRITTEN, variables: { id: userId, limit, nextToken: null } }
    ],
    onCompleted: data => {
      if (data) {
        openStatus = false;
        setCompleteValue(true);
        setmovePageId(postId ? data.updatePost.id : data.createPost.id);
      }
    }
  });

  const theme = "snow";

  const modules = {
    toolbar: "#toolbar"
    // mention: {
    //   allowedChars: /[^\s]+/,
    //   spaceAfterInsert: true,
    //   mentionDenotationChars: ["#"],
    //   defaultMenuOrientation: "top",
    //   offsetTop: 0,
    //   source: (searchTerm: string, renderList: Function) => {
    //     const regexSC = /[~!@#$%^&*()_+|<>?:{}]/;

    //     if (searchTerm.match(regexSC)) {
    //       return;
    //     }

    //     globalMentionFunc = renderList;

    //     searchTags({
    //       variables: {
    //         query: searchTerm
    //       }
    //     });
    //   },
    //   onOpen: () => {
    //     setIsMention(true);

    //     const categoryEl = document.getElementById("category-select");

    //     if (categoryEl) {
    //       const mentionContainer = document.getElementsByClassName("ql-mention-list-container")[0] as HTMLDivElement;
    //       mentionContainer.style.height = "146px";
    //     }
    //   },
    //   onClose: () => {
    //     setIsMention(false);
    //   },
    //   onSelect: (item: IMention, insertItem: Function) => {
    //     setTimeout(() => {
    //       if (enterMention && quill && !arrowMention) {
    //         const cursorMention = quill.getSelection();

    //         quill.insertText(cursorMention!.index, " ");
    //         enterMention = false;
    //       } else {
    //         insertItem(item);
    //       }

    //       arrowMention = false;
    //     }, 1);
    //   }
    // }
  };

  // const formats = ["link", "image", "video", "mention", "custom-video", "custom-image"];
  const formats = ["link", "image", "video", "custom-video", "custom-image"];
  const placeholder = t("enterContent");

  const quillEditor = document.getElementById("quill-editor");

  if (quillEditor && !quill) {
    Quill.register({
      "formats/custom-video": CustomVideoEmbed,
      "formats/custom-image": CustomImageEmbed
    });

    quill = new Quill("#quill-editor", {
      modules: {
        ...modules
      },
      formats,
      placeholder,
      theme
    });

    const tooltipLinkInput = document.getElementById("quill-editor")!.querySelector("input[data-link]") as HTMLInputElement;

    tooltipLinkInput.dataset.link = "https://example.com";

    quill.on("text-change", delta => {
      autoLinkTag({ delta, quill });
    });

    quill.on("selection-change", (range, oldRange) => {
      // if (oldRange && oldRange.index > 0) {
      //   quillFocus = oldRange;
      // }
      tooltipUitl.insideFix();
    });
  }

  const insertToEditor = (url: string, type: string) => {
    if (quill) {
      quill.focus();
      const range = quill.getSelection();
      quill.insertEmbed(range?.index as number, type, url);

      if (range) {
        quill.setSelection(range.index + (range.index > 0 ? 2 : 1), 0);
      }

      handleValueCheck();
    }
  };

  const handleDeleteContent = () => {
    const tempImageAndVideoList: IFile[] = [];
    const tempOtherFileList: IFile[] = [];

    fileList.forEach(item => {
      if (item.type && ["image", "video"].includes(item.type.split("/")[0])) {
        tempImageAndVideoList.push(item);
      } else {
        tempOtherFileList.push(item);
      }
    });

    const imageOrVideo = document.querySelectorAll(".ql-editor img, .ql-editor video") as NodeListOf<HTMLImageElement | HTMLVideoElement>;

    if (tempImageAndVideoList.length > 0 && tempImageAndVideoList.length !== imageOrVideo.length) {
      const urls: string[] = [];
      imageOrVideo.forEach((item: HTMLImageElement | HTMLVideoElement) => urls.push(item.src));

      let tempFileList = tempImageAndVideoList.filter(item => urls.some(item2 => item.url === (item2.split("?").length > 0 ? item2.split("?")[0] : item2)));
      tempFileList = tempFileList.concat(tempOtherFileList);

      const tempFileUrlList = tempFileList.reduce((acc: string[], cur: IFile) => {
        if (cur.url) {
          acc.push(cur.url);
        }
        return acc;
      }, []);

      setFileList(tempFileList);
      setFileUrlList(tempFileUrlList);
    }
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const { currentTarget } = e;

    const maxByte = 100;

    const str = currentTarget.value;
    const strLen = str.length;

    // const strByte = (function(s, b, i, c) {
    //   for (b = i = 0; (c = s.charCodeAt(i++)); b += c >> 11 ? 3 : c >> 7 ? 2 : 1) return b;
    // })(str);
    let rbyte = 0;
    let oneChar = "";

    for (let i = 0; i < strLen; i++) {
      oneChar = str.charAt(i);

      if (escape(oneChar).length > 4) {
        rbyte += 2; //한글2Byte
      } else {
        rbyte++; //영문 등 나머지 1Byte
      }
    }

    if (rbyte > maxByte) {
      setSublenModal(true);
      return;
    }

    setSubjectValue(currentTarget.value);
  };

  const handleClose = () => {
    const appWrite = cookie.get("appWrite");
    if (userAgent.includes("Touchclass") && appWrite) {
      appNative.close();
    }

    openStatus = false;

    setWrite({
      categoryId: undefined,
      postId: undefined,
      writeMode: false
    });
  };

  const handleLock = () => {
    setToast({ id: Math.random(), message: lockState ? t("unlock") : t("lock") });
    setLockState(!lockState);
  };

  // const handleMetion = (elements: NodeListOf<Element>) => {
  //   const mention: string[] = [];
  //   if (elements.length > 0) {
  //     elements.forEach(item => {
  //       mention.push(item.getAttribute("data-value") as string);
  //     });
  //   }
  //   return Array.from(new Set(mention));
  // };

  const getSecreted = (allowSecrets?: allowSecretType, lockState?: boolean) => {
    if (allowSecrets === "ALWAYS") {
      return true;
    }
    if (allowSecrets === "DISABLED") {
      return false;
    }
    return lockState;
  };

  const handleConfirm = () => {
    setConfirmValue(false);

    const originContent = quillEditor?.childNodes[0] as HTMLElement;
    let content = originContent.innerHTML;

    const regexHashTag = /[#][^(?!.|,|;|:|~|'|"|”|’|!|!|+|<|>|@|#|$|%|^|&|*|\\{|\\}|\\[|\]|\\/|\\|\-|\\=|\\`)\s]+/g;
    const regHtml = /(<([^>]+)>)/gi;

    const matchTags = common.decodeHTML(originContent.innerHTML.replace(regHtml, " ")).match(regexHashTag);

    const replaceTag = (match: string, p1: number, p2: string) => {
      const checkMention = "data-denotationChar";
      const p2Check = p2.substr(p1 - (checkMention.length + 5), 19);

      const result = p2Check === checkMention ? match : `<span class="mention" data-value="${match.substr(1)}" data-denotationChar="#">${match}</span>`;
      return result;
    };

    const tags: string[] = [];

    if (matchTags && matchTags.length > 0) {
      const resultHashTag = Array.from(new Set(matchTags.sort((a, b) => b.length - a.length)));

      if (resultHashTag.length > 0) {
        let tempContentEl = originContent.innerHTML;

        if (tempContentEl) {
          resultHashTag.forEach(item => {
            tempContentEl = tempContentEl.replace(new RegExp(`${item}`, "g"), replaceTag).trim();

            tags.push(item.substr(1));
          });
          content = tempContentEl;
        }
      }
    }

    // const mentionTags = document.getElementById("quill-editor")!.querySelectorAll(".mention");

    // const mentionPeople = document.querySelectorAll("[data-denotation-char='@']");
    // const tags: string[] = handleMetion(mentionTags);

    // const people: string[] = handleMetion(mentionPeople);

    const getInputPostData = () => {
      const inputPost = {
        title: subjectValue,
        content,
        secreted: getSecreted(allowSecrets, lockState),
        files: fileUrlList,
        tags
      };

      if (registering) {
        return inputPost;
      } else {
        return { ...inputPost, categoryId: categoryValue?.id };
      }
    };

    WritePostMutation({
      variables: {
        categoryId: categoryValue?.id,
        id: postId,
        input: getInputPostData()
      }
    });
  };

  const editorCurrent = document.getElementsByClassName("ql-editor")[0];

  if (writeData && postId && loadPostCall && editorCurrent) {
    if (editorCurrent) {
      const regSpan = /<(\/span class="mention"|span)([^>]*)>/gi;
      editorCurrent.innerHTML = writeData.getPost.content.replace(regSpan, "");
    }
    loadPostCall = false;
  }

  const handleFileDelete = (url: string) => {
    const editorWrap = document.getElementsByClassName("ql-editor")[0];

    if (editorWrap) {
      editorWrap.childNodes.forEach((item: ChildNode) => {
        if (item.nodeName === "VIDEO") {
          const videoSrc = (item as HTMLVideoElement).querySelector("source")?.getAttribute("src");
          url === videoSrc && item.remove();
        }

        const tempFileList = fileList.filter(item => item.url !== url);
        const tempUrlList = fileUrlList.filter(item => item !== url);

        setFileList(tempFileList);
        setFileUrlList(tempUrlList);
      });
    }

    const fileGroup = document.getElementsByName("file-group");

    for (let i = 0; i < fileGroup.length; i++) {
      if ((fileGroup[i] as HTMLInputElement).value) {
        (fileGroup[i] as HTMLInputElement).value = "";
      }
    }
  };

  const handleCtg = (categoryId: string) => {
    const refreshGetCategory = () => {
      setCategoryValue({ ...categoryValue, id: categoryId });
    };

    refreshGetCategory();
    setCtgModal(false);
  };

  const handleMove = (movePageId: string) => {
    const appWrite = cookie.get("appWrite");

    if (userAgent.includes("Touchclass") && appWrite) {
      const appData = {
        type: "post",
        method: postId ? "update" : "write",
        id: movePageId
      };

      appNative.closeData(appData);
    }

    window.scrollTo(0, 0);

    if (detailMode) {
      setWrite({
        categoryId: undefined,
        postId: undefined,
        writeMode: false
      });
    }

    history.push(`/${cpId ? `${cpId}/` : ""}board/${categoryValue?.id}/view/${movePageId}`);
  };

  const handleValueCheck = () => {
    if (quill) {
      const editorWrap = document.getElementsByClassName("ql-blank")[0];
      const titleContent = document.getElementById("title-input") as HTMLInputElement;

      handleDeleteContent();

      if (editorWrap) {
        setIsContentsEmpty(true);
      } else {
        setIsContentsEmpty(false);
      }

      if (titleContent.value.length > 0 && !editorWrap) {
        setConfirmValue(true);
      } else {
        setConfirmValue(false);
      }
    }
  };

  const handleKeyCheck = (e: KeyboardEvent) => {
    const { target } = e;

    const currentTarget = target as Element;

    const { nodeName } = currentTarget;

    const targetEditor = currentTarget.getAttribute("class")?.includes("ql-editor");

    if (nodeName === "INPUT" || targetEditor) {
      quill && setTimeout(() => handleValueCheck(), 1);
      // if (e.key === "ArrowUp" || e.key === "ArrowDown") {
      //   arrowMention = true;
      // } else if (targetEditor && e.key === "Enter") {
      //   enterMention = true;
      // }
    } else if (e.key === "Escape" || e.key === "Backspace") {
      e.preventDefault();
      if (isContentsEmpty) {
        e.preventDefault();
        setWrite({
          writeMode: false
        });
      } else {
        setCancelModal(true);
      }
    }
  };

  const handleUnload = (event: Event) => {
    event.preventDefault();
    (event || window.event).returnValue = true;
    return true;
  };

  const handlePaste = () => {
    if (quill) {
      removePasteTag();
    }
  };

  useEffect(() => {
    window.addEventListener("writeBackPress", handleClose);
    return () => {
      window.removeEventListener("writeBackPress", handleClose);
    };
  }, []);

  useEffect(() => {
    const { userAgent } = window.navigator;

    window.addEventListener("keydown", handleKeyCheck);
    window.addEventListener("paste", handlePaste);
    if (!isContentsEmpty) {
      window.addEventListener("beforeunload", handleUnload);
    }

    if (userAgent.includes("Touchclass Android")) {
      window.onTogether?.writeOn();
    }

    return () => {
      window.removeEventListener("keydown", handleKeyCheck);
      window.removeEventListener("paste", removePasteTag);
      window.removeEventListener("beforeunload", handleUnload);

      if (!openStatus) {
        openStatus = true;
        loadPostCall = true;
        quill = null;

        if (userAgent.includes("Touchclass Android")) {
          window.onTogether?.writeOff();
        }

        cookie.remove("appWrite");
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fileList, isContentsEmpty]);

  return (
    <Write id={"write-on"}>
      <WriteWrap className={fileList.filter(item => !item.type?.includes("image")).length > 0 ? "file-on" : ""}>
        <Top id="header">
          <TopWrap>
            <Lnb>
              <Menu onClick={isContentsEmpty ? handleClose : () => setCancelModal(true)}>
                <IconBack size={"l"} />
              </Menu>
            </Lnb>
            <Title>{postId ? t("editPost") : t("createPost")}</Title>
            <Rnb>
              <Confirm active={confirmValue} onClick={confirmValue ? handleConfirm : undefined}>
                {t("confirm")}
              </Confirm>
            </Rnb>
          </TopWrap>
        </Top>
        <SelectWrap type="button" id="category-select" onClick={() => setCtgModal(true)}>
          <CurCtg>
            {categoryValue?.title}
            <IconArrow01 direction={"bottom"} />
          </CurCtg>
        </SelectWrap>
        <Subject>
          <Input id="title-input" type="text" value={subjectValue} placeholder={t("enterTitle")} onChange={handleChange} />
        </Subject>
        <EditorWrap className={"editor-wrap"}>
          {/* {common.agent()[0] === "Touchclass iOS" && <DummyFocus id="dummy-focus" />} */}
          <DummyFocus id="dummy-focus" />
          <Toolbar
            id="toolbar"
            image
            video
            file
            link
            multiple
            fileList={fileList}
            fileUrlList={fileUrlList}
            setFileList={setFileList}
            setFileUrlList={setFileUrlList}
            insertCallback={insertToEditor}
            quill={quill}
          >
            {allowSecrets === "ENABLED" && (
              <button onClick={handleLock}>
                <IconLock lock={lockState} color={lockState ? themeCur.light.COLORS.BG_BRAND : undefined} full={lockState} />
              </button>
            )}
          </Toolbar>
          <div id={"quill-editor"} />
        </EditorWrap>
        {fileList.length > 0 && (
          <FileWrap>
            {fileList.map((item, index) => (
              <File {...item} status={"write"} callDelete={handleFileDelete} key={index} />
            ))}
          </FileWrap>
        )}
      </WriteWrap>
      {/* {isMention && <MentionTitle>인기태그</MentionTitle>} */}
      <Modal
        show={completeValue}
        view={"alert"}
        title={t("success")}
        text={postId ? t("successPostText2") : t("successPostText")}
        confirmText={t("ok")}
        confirm={() => handleMove(movePageId)}
      >
        <IconEmptyCheck />
      </Modal>
      <Modal show={sublenModal} view={"alert"} title={""} confirm={() => setSublenModal(false)}>
        <IconEmptyComment />
        <WarningTxt>{t("warningTitle")}</WarningTxt>
      </Modal>
      <Modal show={ctgModal} view={"content"} dimClose={() => setCtgModal(false)}>
        <Category writeMode categoryGroupId={categoryData?.parent.id} categoryValue={categoryValue} handleCtg={handleCtg} closeCallback={setCtgModal} />
      </Modal>
      {toast && <Toast toast={toast} setToast={setToast} />}
      <Modal
        show={Boolean(changeTopicModal)}
        view={"alert"}
        title={""}
        text={t("wantToCancelWrite")}
        confirmText={t("변경하기")}
        confirm={() => setChangeTopicModal(undefined)}
        cancelText={t("cancel")}
        cancel={() => setChangeTopicModal(undefined)}
      >
        <IconEmptyAlert />
      </Modal>

      <Modal
        show={cancelModal}
        view={"alert"}
        title={t("cancelWrite")}
        text={t("wantToCancelWrite")}
        confirmText={t("ok")}
        confirm={handleClose}
        cancelText={t("cancel")}
        cancel={() => setCancelModal(false)}
      >
        <IconEmptyCancel />
      </Modal>
    </Write>
  );
};

WriteComponent.defaultProps = {};

export default withRouter(WriteComponent);

const Write = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 11;
  -webkit-overflow-scrolling: touch;

  .ql-snow.ql-toolbar .modal button.confirm {
    background: ${p => p.theme.COLORS.BG_BRAND};
    height: auto;
    padding: 10px 13px;
  }

  .ql-snow.ql-toolbar .modal button.cancel {
    background: ${p => p.theme.COLORS.BG_SECONDARY};
    height: auto;
    padding: 10px 13px;
  }
`;

const WriteWrap = styled.div`
  margin: 0 auto;
  padding: 42px 0 20px 0;
  width: 100%;
  max-width: 650px;
  height: 100%;
  background: ${p => p.theme.COLORS.BG_BODY};
  overflow-x: hidden;
  overflow-y: scroll;
  z-index: 10;
  -webkit-overflow-scrolling: touch;

  &.file-on {
    padding: 42px 0 50px 0;

    & .editor-wrap {
      height: calc(100vh - 270px);
    }
  }
`;

const Top = styled.header`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 42px;
  background-color: ${p => p.theme.COLORS.BORDER_REGULAR};
  z-index: 5;
`;

const TopWrap = styled.header`
  position: relative;
  margin: 0 auto;
  padding: 8px 13px 10px 10px;
  width: 100%;
  max-width: 650px;
  height: 42px;
  background-color: ${p => p.theme.COLORS.BG_BODY};
  z-index: 5;
`;

const Lnb = styled.div`
  position: absolute;
  top: 0;
  left: 10px;
`;

const Rnb = styled.div`
  position: absolute;
  top: 0;
  right: 13px;

  & a {
    vertical-align: top;
  }
`;

const Title = styled.h2`
  ${p => p.theme.TEXTS.TOP_TITLE};
  color: ${p => p.theme.COLORS.TEXT_REGULAR};
  text-align: center;
`;

const Menu = styled.button`
  padding: 0;
`;

const Confirm = styled.button<IWriteConfirm>`
  display: flex;
  align-items: center;
  padding: 0 7px;
  height: 40px;
  color: ${p => (p.active ? p.theme.COLORS.TEXT_BRAND : p.theme.COLORS.TEXT_DISABLED)};
  font-weight: 500;
`;

const SelectWrap = styled.button`
  position: relative;
  background: none;
  width: 100%;
  text-align: left;
  margin: 0;
  padding: 0;

  & select {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
  }
`;

const CurCtg = styled.label`
  ${p => p.theme.TEXTS.BOARD_CONTENT};
  display: block;
  padding: 10px 70px 10px 20px;
  height: 44px;

  & svg {
    position: absolute;
    top: 13px;
    right: 16px;
  }
`;

const Subject = styled.div`
  width: 100%;
  border-bottom: 4px solid ${p => p.theme.COLORS.BORDER_REGULAR};
`;

const WarningTxt = styled.div`
  margin-top: 20px;
  width: 250px;
`;

const Input = styled.input`
  padding: 10px 20px;
  width: 100%;
  border: 0 none;
  ${p => p.theme.TEXTS.TXT_PLACEHOLDER_LG};
`;

const EditorWrap = styled.div`
  width: 100%;
  height: calc(100vh - 140px);

  & .ql-container.ql-snow {
    border: 0 none;
  }

  & .ql-toolbar.ql-snow {
    padding: 8px 15px;
    border: 0 none;
    border-bottom: 1px solid ${p => p.theme.COLORS.BORDER_REGULAR};
  }

  & .ql-editor.ql-blank::before {
    left: 20px;
  }

  & .ql-editor {
    padding: 15px 20px;

    & img,
    & video {
      width: 100%;
    }

    & iframe {
      width: calc(100vw - 30px);
      height: calc(56vw - 32px);
      max-width: 100%;
      max-height: 100%;
    }
  }

  & .ql-tooltip {
    &:before {
      content: "입력된 URL:";
    }

    & a.ql-action:after {
      content: "수정";
    }

    & a.ql-remove:before {
      content: "삭제";
    }

    &.ql-editing {
      &[data-mode^="link"] {
        &:before {
          content: "URL 입력:";
        }
      }

      & a.ql-action:after {
        content: "완료";
      }
    }
  }
`;

const FileWrap = styled.div`
  margin-top: 74px;
  padding: 0 20px;
`;

const DummyFocus = styled.input`
  position: absolute;
  top: 50%;
  left: 0;
  opacity: 0;
  width: 0;
  height: 0;
  z-index: -1;
`;

// const MentionTitle = styled.h2`
//   position: fixed;
//   top: 0;
//   left: 0;
//   padding: 10px 20px 0;
//   width: 100%;
//   max-width: 650px;
//   color: ${p => p.theme.COLORS.TEXT_BRAND};
//   font-size: 16px;
//   background: ${p => p.theme.COLORS.BG_BODY};
//   z-index: 9002;

//   @media screen and (min-width: 650px) {
//     left: 50% !important;
//     margin-left: -325px;
//   }
// `;
