import React, { useEffect, useRef, useState } from "react";
import styled from "styles/theme";
import { useTranslation } from "react-i18next";
import queryString from "query-string";
import withLayout from "layouts/EmptyLayout";
import { FONT_SECONDARY } from "styles/Reset";
import { Modal, Spinner, Toast } from "styles";
import { CommentWrite, Empty, File, LoadingItem } from "components/Board";
import Author from "components/Board/Item/Author";
import Comment from "components/Board/Item/Comment";
import ImageSlider from "components/Common/ImageSlider";
import { appNative, bounceRefetch } from "utils";
import { IconBack, IconClose, IconComment, IconDownload, IconEmptyCheck, IconEmptyTrash, IconLike, IconMore, IconScrape } from "assets/icon/svg";
import { useMutation, useQuery } from "@apollo/react-hooks";
import { GET_POST, LIST_COMMENTS, REMOVE_POST, TOGGLE_LIKE_POST, TOGGLE_SCRAPE_POST } from "api/Board";
import { css } from "@emotion/core";
import { getCookieState } from "apollo/LocalState";
import { MenuButtons } from "../../components/Common/MenuButtons";
import { ReportTypography } from "../../components/Common/ReportTypography";
import { ReportModal } from "../../components/Common/ReportModal";
import { getFileUrl } from "../../components/Board/File";
import DOMPurify from "dompurify";

let loadConfirm: boolean;
let commentLoad = false;
let nextToken: string | undefined | null;
let moreLoad: boolean;

const DetailView: React.FC<RouteComponentProps> = ({ match, location, history, user, setWriteMode, setWriteInfo, live }) => {
  const { cpId } = getCookieState;

  const { t } = useTranslation("Detail");

  const { userAgent } = window.navigator;
  const { hash, search, origin } = location;
  const { categoryId, postId } = match.params;
  const queryPath = queryString.parse(search);

  const [more, setMore] = useState<boolean>(false);
  const [removeModal, setRemoveModal] = useState<boolean>(false);
  const [toast, setToast] = useState<IToast>();
  const [screenModal, setScreenModal] = useState<boolean>(false);
  const [errorModal, setErrorModal] = useState<boolean>(false);
  const [cancelReportModal, setCancelReportModal] = useState<boolean>(false);
  const [blockModal, setBlockModal] = useState<boolean>(false);
  const [cancelBlockModal, setCancelBlockModal] = useState<boolean>(false);
  const [showHeader, setShowHeader] = useState<boolean>(true);
  const [imageIndex, setImageIndex] = useState<number>();
  const [imageFiles, setImageFiles] = useState<IFile[]>([]);
  const moreRef = useRef<HTMLDivElement>(null);
  const [commentActive, setCommentActive] = useState<boolean>(false);
  const [sort, setSort] = useState<SortType>("CREATED_AT");
  const commentWrapRef = useRef<HTMLDivElement>(null);
  const commentInputRef = useRef<HTMLDivElement>(null);

  const { data: detailData, loading: detailLoading, refetch: postRefetch } = useQuery(GET_POST, {
    variables: { id: postId },
    fetchPolicy: "cache-and-network",
    onCompleted: data => {
      if (data) {
        if (data.getPost.restricted) {
          document.querySelector("#live-status-bar")?.classList.add("deep");
          setErrorModal(true);
        }
      }
    },
    onError: () => {
      document.querySelector("#live-status-bar")?.classList.add("deep");
      setErrorModal(true);
    }
  });

  const { data: commentData, loading: commentLoading, refetch: commentRefetch, fetchMore: commentMore } = useQuery(LIST_COMMENTS, {
    variables: { postId, pageInfo: { limit: 20, nextToken: null }, options: { sortBy: sort } },
    fetchPolicy: "cache-and-network"
  });

  const preventDownload = detailData?.getPost?.category?.allowAttachmentFileDownload === `DISABLED`;

  const commentCount = document.getElementById("comment-count");

  if (commentData && commentCount) {
    commentCount.innerText = commentData?.listComments.totalCount;
  }

  const [toggleLikePostMutation] = useMutation(TOGGLE_LIKE_POST);
  const [toggleScrapePostMutation] = useMutation(TOGGLE_SCRAPE_POST);

  const [RemovePostMutation] = useMutation(REMOVE_POST, {
    onCompleted: data => {
      if (userAgent.includes("Touchclass")) {
        const appData = {
          type: "post",
          method: "delete",
          id: data.removePost.id
        };

        appNative.closeData(appData);
      }

      if (data.removePost.id) {
        history.push(`/${cpId ? `${cpId}/` : ""}board/${detailData?.getPost.category.id}`);

        window.location.reload();
      }
    }
  });

  const moreEvent = (event: MouseEvent) => {
    event.stopPropagation();
    const dom = moreRef.current;
    if (dom) {
      const rect = dom.getBoundingClientRect();
      if (!(rect.top - 84 < event.clientY && rect.bottom > event.clientY && rect.left < event.clientX && rect.right > event.clientX)) {
        setMore(false);
        setCommentActive(false);
      }
    }
  };

  moreLoad = commentLoading;
  nextToken = commentData?.listComments.nextToken;
  loadConfirm = commentData?.listComments.nextToken ? true : false;

  const handleRefetch = () => {
    postRefetch();
    commentRefetch();
  };

  const handleBounce = (event: TouchEvent) => {
    const bounceTarget = document.getElementById("detail-wrap");

    if (bounceTarget) {
      bounceRefetch.start(event, bounceTarget, handleRefetch);
    }
  };

  const handleClose = () => {
    if (queryPath.appDetail) {
      const appData = {
        type: "detail",
        method: "close"
      };

      setErrorModal(false);

      appNative.closeData(appData);
    }

    if (queryPath.touchhome) {
      window.location.replace(origin + `/${cpId ? `${cpId}/` : ""}board`);
    } else {
      window.history.go(-1);
    }
  };

  const handleRemove = (id: string) => {
    RemovePostMutation({
      variables: {
        id
      }
    });
  };

  const handleCommentIn = () => {
    detailData.getPost.category.canWriteComments && setCommentActive(true);
  };

  const handleCommentSort = (sort: SortType) => {
    setSort(sort);
    commentRefetch();
  };

  const handleWrite = () => {
    setWriteInfo({
      categoryId,
      postId
    });
    setWriteMode(true);
  };

  const handleScrape = async () => {
    try {
      const { data } = await toggleScrapePostMutation({ variables: { id: postId } });
      const message = data.toggleScrapePost.me.scraped ? t("scrape") : t("scrapeOff");
      setToast({ id: Math.random(), message });
    } catch (err) {
      // console.log(err);
    }
  };

  const collectImages = () => {
    const images = document.querySelectorAll("#view-content img");
    if (detailData) {
      const tempImages: IFile[] = [];
      images.forEach((item, index) => {
        tempImages.push({ url: item.getAttribute("src")?.split("?")[0] });
        detailData.getPost.files.forEach((item2: IFile) => {
          if (item2.url === tempImages[index].url) {
            tempImages[index].name = item2.name;
          }
        });
      });
      setImageFiles(tempImages);
      setImageIndex(0);
    }
  };

  const handleContentImages = (target: EventTarget) => {
    const images = document.querySelectorAll("#view-content img");

    images.forEach((item, index) => {
      if (item === target) {
        setImageIndex(index);
        history.push(`#${imageFiles[index].name}`);
        setScreenModal(true);
        document.querySelector("#live-status-bar")?.classList.add("deep");
      }
    });
  };

  const handleContentTags = (target: HTMLElement) => {
    const tags = document.querySelectorAll("#view-content .mention");

    tags.forEach(item => {
      if (item === target) {
        const value = item.getAttribute("data-value");
        //const { origin } = window.location;
        // window.location.href = `${origin}${cpId ? `/${cpId}` : ""}/board/search?keyword=${value}&type=TAG`;

        history.push(`${cpId ? `/${cpId}` : ""}/board/search?keyword=${value}&type=TAG`);
      }
    });
  };

  const handleContent = (event: MouseEvent) => {
    const { target } = event;
    if (target && detailData) {
      const targetEl = target as HTMLElement;
      const tagName = targetEl.tagName;

      if (tagName === "IMG") {
        handleContentImages(target);
      }

      if (targetEl.textContent?.trim().indexOf("#") === 0) {
        handleContentTags(targetEl);
        // if (targetEl.parentElement) {
        //   handleContentTags(targetEl.parentElement);
        // }
      }
    }
  };

  const toggleModalHeader = () => {
    setShowHeader(!showHeader);
  };

  const simplyCloseModal = () => {
    setScreenModal(false);
    document.querySelector("#live-status-bar")?.classList.remove("deep");
  };

  const closeScreenModal = () => {
    window.history.go(-1);
    setScreenModal(false);
    document.querySelector("#live-status-bar")?.classList.remove("deep");
  };

  const confirmRemoveModal = (value: boolean) => {
    setRemoveModal(value);
    if (value) {
      document.querySelector("#live-status-bar")?.classList.add("deep");
    } else {
      document.querySelector("#live-status-bar")?.classList.remove("deep");
    }
  };

  const commentWrap = document.getElementById("comment-wrap");

  if (hash && commentWrap && !commentLoad && !detailLoading && !commentLoading) {
    const rect = commentWrap?.getBoundingClientRect();
    const detailView = document.getElementById("detail-view");
    detailView?.scrollTo(0, Number(rect?.top));
    commentLoad = true;
  }

  useEffect(() => {
    const handleCommentAdd = () => {
      const commentListWrap = commentWrapRef.current;
      const commentInput = commentInputRef.current;

      if (!moreLoad && commentListWrap && nextToken) {
        if (commentInput && commentListWrap.children.length > 0) {
          const commentInputRect = commentInput.getBoundingClientRect();
          const commentListFirstRect = commentListWrap.children[0].getBoundingClientRect();

          const commentInputHeight = commentInputRect.y + commentInputRect.height;

          if (commentInput.className.indexOf("fixed") < 0 && commentInputRect.y < 43) {
            commentInput.classList.add("fixed");
            // commentPosition = commentInput.parentElement?.parentElement?.scrollTop;
          } else if (commentInput.className.indexOf("fixed") > -1 && commentInputHeight <= commentListFirstRect.y) {
            commentInput.classList.remove("fixed");
          }
        }

        const triggerElement = commentListWrap.children[commentListWrap.children.length - 10];

        if (triggerElement) {
          const triggetRect = triggerElement.getBoundingClientRect();

          if (triggetRect.y < 10 && loadConfirm) {
            loadConfirm = false;

            commentMore({
              variables: {
                postId,
                pageInfo: { limit: 20, nextToken: nextToken }
              },
              updateQuery: (prev: ICommentData, { fetchMoreResult }) => {
                if (!fetchMoreResult || prev.listComments.totalCount === prev.listComments.items.length) return prev;

                nextToken = fetchMoreResult.listComments.nextToken;

                return {
                  listComments: {
                    ...prev.listComments,
                    nextToken: fetchMoreResult.listComments.nextToken,
                    items: [...prev.listComments.items, ...fetchMoreResult.listComments.items]
                  }
                };
              }
            });
          }
        }
      }
    };

    const contentView = document.getElementById("view-content");

    if (contentView) {
      contentView.addEventListener("click", handleContent);
      if (!imageFiles.length) {
        collectImages();
      }
    }

    window.addEventListener("click", moreEvent);
    window.addEventListener("scroll", handleCommentAdd);
    window.addEventListener("load", collectImages);
    window.addEventListener("popstate", simplyCloseModal);

    window.addEventListener("touchstart", handleBounce);
    window.addEventListener("touchmove", bounceRefetch.move);
    window.addEventListener("touchend", bounceRefetch.end);

    return () => {
      window.removeEventListener("click", moreEvent);
      window.removeEventListener("scroll", handleCommentAdd);
      window.removeEventListener("load", collectImages);
      window.removeEventListener("popstate", simplyCloseModal);

      if (contentView) {
        contentView.removeEventListener("click", handleContent);
      }

      commentLoad = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [postId, commentMore, commentData, detailData, imageIndex]);

  const handleNplayer = () => {
    const viewContent = document.getElementById("view-content");
    const videoArr = viewContent?.querySelectorAll("video")[0];

    const videoInfo = videoArr?.getBoundingClientRect();

    const videoValue = {
      url: videoArr?.getAttribute("src"),
      width: videoArr?.clientWidth,
      height: videoArr?.clientHeight,
      top: videoInfo?.top,
      left: videoInfo?.left
    };

    if (userAgent.includes("Touchclass iOS")) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      window.webkit?.messageHandlers.onTogether.postMessage({ action: "mediaPlay", value: videoValue });
    }
  };

  const eventDisable = (detailData?.getPost.me.reported ?? false) || !!detailData?.getPost.blockedBy;

  return (
    <Detail>
      <Top id="header" live={live}>
        <TopWrap>
          <Lnb>
            <Menu onClick={handleClose}>
              <IconBack size={"l"} />
            </Menu>
          </Lnb>
          {detailData && !detailData?.getPost.restricted && (
            <Category>
              {userAgent.includes("Touchclass") && <span>{detailData.getPost.category.title}</span>}
              {!userAgent.includes("Touchclass") && <a href={`/${cpId ? `${cpId}/` : ""}board/${detailData.getPost.category.id}`}>{detailData.getPost.category.title}</a>}
            </Category>
          )}
          <Rnb>
            {!detailData?.getPost.me.reported && (
              <More onClick={() => setMore(!more)}>
                <IconMore size={"l"} />
              </More>
            )}

            {more && (
              <MoreOption ref={moreRef} style={{ minWidth: detailData?.getPost.me.posted ? "60px" : "100px" }}>
                <MenuButtons
                  me={detailData?.getPost.me.posted ?? false}
                  type={"post"}
                  reported={detailData?.getPost.me.reported ?? false}
                  blockedBy={detailData?.getPost.blockedBy}
                  onWrite={handleWrite}
                  onRemove={() => confirmRemoveModal(true)}
                  onReport={() => {
                    history.push(`/${cpId ? `${cpId}/` : ""}board/report/post/${postId}`);
                  }}
                  onBlock={() => {
                    setBlockModal(true);
                  }}
                  onCancelBock={() => {
                    setCancelBlockModal(true);
                  }}
                  onCancelReport={() => {
                    setCancelReportModal(true);
                  }}
                />
              </MoreOption>
            )}
          </Rnb>
        </TopWrap>
      </Top>
      {!detailLoading && detailData && (
        <FixedLoading live={live}>
          <Spinner />
        </FixedLoading>
      )}
      <DetailWrap id={"detail-wrap"} live={live}>
        <Author
          id={postId}
          feed={true}
          isPost={true}
          author={detailData?.getPost.author}
          picked={detailData?.getPost.picked}
          createdAt={!detailData?.getPost.restricted && detailData?.getPost.createdAt}
          hitCount={detailData?.getPost.hitCount}
          me={detailData?.getPost.me}
          secreted={detailData?.getPost.secreted}
          blockedBy={detailData?.getPost.blockedBy}
          reported={detailData?.getPost.me.reported}
        />
        {!detailData?.getPost.me.reported && !detailData?.getPost.blockedBy ? (
          <PostWrap>
            <Title>{detailData?.getPost.title}</Title>
            {/* {DEV_STATUS && (
            <div>
              <Button onClick={handleNplayer}>n플레이어 테스트</Button>
            </div>
          )} */}
            <Content
              id={"view-content"}
              dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(detailData?.getPost.content.replace('controls=""', 'controls="" controlsList="nodownload"')) }}
            />
            {detailData?.getPost.files.length > 0 && (
              <FileWrap>
                {detailData.getPost.files.map((item: IFile, index: number) => (
                  <File {...item} key={index} preventDownload={preventDownload} />
                  // <>{(item.type as string).indexOf("image") > -1 ? null : (item.type as string).indexOf("video") > -1 ? null : <File {...item} key={index} />}</>
                ))}
              </FileWrap>
            )}
          </PostWrap>
        ) : (
          <ReportTypography blockedBy={detailData?.getPost.blockedBy} reported={detailData?.getPost.me.reported} isComment={false} />
        )}
        <PostInfo className={"feed"}>
          <ElNum
            disable={eventDisable}
            onClick={() => {
              !eventDisable && toggleLikePostMutation({ variables: { id: postId } });
            }}
          >
            <IconLike active={detailData?.getPost.me.liked ? true : false} />
            {detailData?.getPost.likeCount}
          </ElNum>
          {
            <ElNum
              disable={eventDisable}
              onClick={() => {
                !eventDisable && handleScrape();
              }}
            >
              <IconScrape active={detailData?.getPost.me.scraped ? true : false} />
              {detailData?.getPost.scrapeCount}
            </ElNum>
          }
          {!detailData?.getPost.restricted && detailData?.getPost.category.allowComments !== "DISABLED" && (
            <ElNum disable={false} className="comment">
              <IconComment />
              {commentData?.listComments.totalCount}
            </ElNum>
          )}
        </PostInfo>
      </DetailWrap>
      {!detailData?.getPost.restricted && detailData?.getPost.category.allowComments !== "DISABLED" && (
        <CommentWrap id={"comment-wrap"}>
          {!eventDisable && (
            <CommentInputFixed ref={commentInputRef}>
              <CommentCount dangerouslySetInnerHTML={{ __html: t("commentCount") }} />
              {detailData && !commentActive && (
                <CommentInputWrap className={detailData.getPost.category.canWriteComments ? "" : "disabled"}>
                  <CommentInputText
                    dangerouslySetInnerHTML={{ __html: t(detailData.getPost.category.canWriteComments ? "leaveComment" : "permissionComment") }}
                    onClick={handleCommentIn}
                  />
                </CommentInputWrap>
              )}
              {commentActive && <CommentWrite className={"input-area"} author={user} commentSort={sort} setWrite={setCommentActive} />}
            </CommentInputFixed>
          )}

          <CommentList ref={commentWrapRef}>
            {commentData?.listComments.totalCount > 0 && (
              <Sort>
                <button type="button" className={sort === "CREATED_AT" ? "active" : "CREATED_AT"} onClick={() => handleCommentSort("CREATED_AT")}>
                  {t("newest")}
                </button>
                <button type="button" className={sort === "LIKES" ? "active" : "LIKES"} onClick={() => handleCommentSort("LIKES")}>
                  {t("mostLiked")}
                </button>
              </Sort>
            )}
            {commentData?.listComments.items.map((item: IComment, index: number) => (
              <Comment {...item} post={{ id: postId }} commentSort={sort} view={true} key={index} preventDownload={preventDownload} />
            ))}
            {loadConfirm && <LoadingItem comment />}
          </CommentList>
          {commentData?.listComments.totalCount === 0 && detailData?.getPost.category.canWriteComments && !commentActive && <Empty text={t("firstCommentText")} comment />}
        </CommentWrap>
      )}
      <Modal
        show={removeModal}
        view={"alert"}
        title={t("delete")}
        text={t("deleteText")}
        confirmText={t("yes")}
        confirm={() => handleRemove(postId)}
        cancelText={t("cancel")}
        cancel={() => confirmRemoveModal(false)}
        dimClose={() => confirmRemoveModal(false)}
      >
        <IconEmptyTrash />
      </Modal>
      {(imageIndex || imageIndex === 0) && hash && imageFiles.length > 0 && (
        <Modal deep show={screenModal} dimClose={toggleModalHeader}>
          {showHeader && (
            <ModalHeader>
              <CloseButton onClick={closeScreenModal}>
                <IconClose size={"l"} color={"#FFF"} />
              </CloseButton>
              <ImageIndex>
                <CurrentIndex>{imageIndex + 1}</CurrentIndex>
                <FileLength>{" / " + imageFiles.length}</FileLength>
              </ImageIndex>

              {cpId !== "everland" && !preventDownload && (
                <DownloadButton>
                  <Download href={getFileUrl(imageFiles[imageIndex].url, preventDownload)} download={imageFiles[imageIndex].name}>
                    <IconDownload size={"l"} color={"#FFF"} />
                  </Download>
                </DownloadButton>
              )}
            </ModalHeader>
          )}
          <ImageSlider files={imageFiles} setImageIndex={setImageIndex} imageIndex={imageIndex} onClick={toggleModalHeader} />
        </Modal>
      )}
      {!detailLoading && errorModal && (
        <Modal
          show={errorModal}
          view={"alert"}
          title={""}
          text={t(detailData?.getPost.restricted ? "readPermission" : "errorPost")}
          confirmText={t("yes")}
          // confirm={() => history.push(`/${cpId ? `${cpId}/` : ""}board`)}
          confirm={() => window.location.replace(`${origin}/${cpId ? `${cpId}/` : ""}board`)}
        >
          <IconEmptyCheck />
        </Modal>
      )}
      <ReportModal
        id={postId}
        isComment={false}
        userId={detailData?.getPost.author?.id}
        userName={detailData?.getPost.author?.name}
        cancelReportModal={cancelReportModal}
        blockModal={blockModal}
        cancelBlockModal={cancelBlockModal}
        onCancelReportModal={setCancelReportModal}
        onBlockModal={setBlockModal}
        onCancelBlockModal={setCancelBlockModal}
      />
      {/*{report && <Report postId={postId} callback={setReport} />}*/}
      {toast && <Toast toast={toast} setToast={setToast} />}
    </Detail>
  );
};

export default withLayout(DetailView);

const LIVE_ON = css`
  padding-top: 42px;
`;

const Detail = styled.div`
  position: relative;
  top: 42px;
  width: 100%;
  background: ${p => p.theme.COLORS.BG_BODY};
  z-index: 11;
`;

const Top = styled.header<ILive>`
  position: fixed;
  top: ${p => (p.live ? 42 : 0)}px;
  left: 0;
  width: 100%;
  height: 42px;
  background-color: ${p => p.theme.COLORS.BORDER_REGULAR};
  z-index: 3;
`;

const TopWrap = styled.header`
  position: relative;
  margin: 0 auto;
  padding: 8px 10px 10px 10px;
  width: 100%;
  max-width: 650px;
  height: 42px;
  background-color: ${p => p.theme.COLORS.BG_BODY};
  z-index: 3;
`;

const Lnb = styled.div`
  position: absolute;
  top: 0;
  left: 10px;
  z-index: 10;
`;

const Rnb = styled.div`
  position: absolute;
  top: 0;
  right: 13px;

  & a {
    vertical-align: top;
  }
`;

const Category = styled.h2`
  ${p => p.theme.TEXTS.TOP_TITLE};
  color: ${p => p.theme.COLORS.TEXT_BRAND};
  text-align: center;

  & a {
    display: inline-block;
    max-width: calc(100% - 100px);
    height: 24px;
    color: ${p => p.theme.COLORS.TEXT_BRAND};
    text-overflow: ellipsis;
    white-space: nowrap;
    vertical-align: top;
    overflow: hidden;

    &.none {
      text-decoration: none;
    }
  }
`;

const Menu = styled.button`
  padding: 0;
`;

const More = styled.button`
  display: flex;
  align-items: center;
  padding: 0;
  height: 40px;
`;

const MoreOption = styled.div`
  position: absolute;
  top: 40px;
  right: 5px;
  padding: 10px 0;
  border-radius: 4px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.25);
  background: ${p => p.theme.COLORS.BG_REGULAR};
  z-index: 2;

  & button,
  & a {
    ${p => p.theme.TEXTS.CONTROL_TXT}
    display: flex;
    justify-content: center;
    padding: 1px 7px 2px;
    width: 100%;
    min-height: 32px;
    font-weight: 400;
    word-break: keep-all;
    text-align: center;
  }
`;

const DetailWrap = styled.div<ILive>`
  position: relative;
  background: ${p => p.theme.COLORS.BG_BODY};
  ${p => p.live && LIVE_ON}
`;

const PostWrap = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  padding: 20px;

  img {
    cursor: pointer;
  }
`;

const Title = styled.h2`
  ${p => p.theme.TEXTS.BOARD_TITLE};
  order: 0;

  & svg {
    vertical-align: -2px;
  }
`;

const Content = styled.div`
  ${p => p.theme.TEXTS.BOARD_CONTENT};
  margin-top: 12px;
  word-break: break-all;

  & a,
  & a:link,
  & a:visited {
    color: ${p => p.theme.COLORS.TEXT_LINK};
  }

  & strong {
    font-weight: bold;
  }

  & s {
    font-weight: inherit;
    font-style: inherit;
    text-decoration: line-through;
  }

  & u {
    font-weight: inherit;
    font-style: inherit;
    text-decoration: underline;
  }

  & em {
    font-weight: inherit;
    font-style: italic;
  }

  & img {
    width: 100%;
  }

  & iframe {
    width: calc(100vw - 40px);
    height: calc(56vw - 34px);
    max-width: 100%;
    max-height: 100%;
  }

  & video {
    width: 100%;
  }

  & .mention {
    cursor: pointer;
  }
`;

const FileWrap = styled.div`
  margin-top: 15px;
`;

const PostInfo = styled.div`
  ${p => p.theme.TEXTS.LIST_EL_NUM};
  order: 2;
  display: flex;
  position: relative;
  margin-top: 6px;
  padding: 0 20px;
  border-top: 1px solid ${p => p.theme.COLORS.BORDER_REGULAR};
  z-index: 1;

  &.feed {
    align-items: center;
    margin-top: 0;
    height: 54px;
  }
`;

interface ElNumProps {
  disable: boolean;
}

const ElNum = styled.span<ElNumProps>`
  ${p => p.theme.TEXTS.LIST_EL_NUM};
  display: flex;
  align-items: center;
  cursor: ${p => (p.disable ? "auto" : "pointer")};
  opacity: ${p => (p.disable ? 0.4 : 1)};

  & svg {
    margin-right: 2px;
  }

  & + span {
    margin-left: 15px;
  }

  &.comment {
    ${p => p.theme.TEXTS.LIST_COMMENT_NUM};
    margin-left: auto;

    & svg {
      margin-right: 4px;
    }

    cursor: initial;
  }
`;

const CommentWrap = styled.div`
  padding-bottom: 120px;
`;

const CommentInputFixed = styled.div`
  position: sticky;
  top: 42px;
  padding: 20px;
  border-top: 4px solid ${p => p.theme.COLORS.BORDER_REGULAR};
  background: ${p => p.theme.COLORS.BG_BODY};
  z-index: 5;

  & > .input-area {
    padding: 0;
  }
`;

const CommentCount = styled.div`
  display: flex;
  align-items: center;
  font-size: 14px;
  line-height: 22px;

  strong {
    ${FONT_SECONDARY};
    line-height: 19px;
    font-size: 16px;
    font-weight: 500;
  }
`;

const CommentInputWrap = styled.div`
  margin-top: 10px;
  padding: 10px;
  min-height: 42px;
  border: 1px solid ${p => p.theme.COLORS.BORDER_REGULAR};
  overflow: hidden;

  &.active {
    height: auto;
    overflow: auto;

    & textarea {
      height: 110px !important;
    }
  }

  &.disabled {
    border-color: ${p => p.theme.COLORS.BG_LIGHT02};
    background: ${p => p.theme.COLORS.BG_LIGHT04};
  }
`;

const CommentInputText = styled.p`
  margin: 0;
  padding: 0;
  width: 100%;
  height: 22px;
  line-height: 22px;
  color: ${p => p.theme.COLORS.TEXT_PLACEHOLDER};
  border: 0 none;

  &.disabled {
    color: ${p => p.theme.COLORS.TEXT_SECONDARY};
    background: ${p => p.theme.COLORS.BG_LIGHT04};
  }
`;

const Sort = styled.div`
  padding: 0 20px;

  button {
    ${p => p.theme.TEXTS.INFO_SORT}
    position: relative;
    padding: 0 7px;

    &:first-of-type {
      padding-left: 0;
    }
  }
`;

const CommentList = styled.div``;

const ModalHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: fixed;
  top: 0;
  left: 0;
  padding: 0 10px;
  width: 100%;
  height: 46px;
  background: ${p => p.theme.COLORS.BG_OVERLAY4};
  z-index: 10;

  & button {
    padding: 0;
    height: 40px;
  }

  & svg {
    position: relative;
    z-index: 5;
  }
`;
const CloseButton = styled.button``;

const ImageIndex = styled.div``;

const CurrentIndex = styled.span`
  ${p => p.theme.TEXTS.FILE_INDEX};
`;

const FileLength = styled.span`
  ${p => p.theme.TEXTS.FILE_LENGTH};
`;

const DownloadButton = styled.button``;

const Download = styled.a``;

const FixedLoading = styled.div<ILoadingFixedStyle>`
  position: absolute;
  top: ${p => (p.live ? 112 : 70)}px;
  left: 0;
  width: 100%;
  border: 0 none !important;
  z-index: 0;
`;
